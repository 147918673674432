import { Style, VersionModel } from '@sitecore-feaas/sdk'
import { ContextType } from 'react'
import { createContextProvider } from '../hooks/useContextProvider.js'
import type * as CK from '@sitecore-feaas/ckeditor5'

export const [VersionContext, useVersionContextProvider] = createContextProvider(() => {
  return {
    error: null as Error,
    node: null as HTMLElement,
    breakpoint: null as Style.Rule<'breakpoint'>,
    snapshotStatus: null as VersionModel['status'],
    needsWrite: false,
    version: null as VersionModel,
    openBar: null as 'breakpoint' | 'status'
  }
}, '__FEAASVersionContext')
export type VersionContext = ContextType<typeof VersionContext>[0]
export type VersionContextSetter = ContextType<typeof VersionContext>[1]

export function saveVersionData(editor: CK.Editor, version: VersionModel) {
  return version.saveData({
    view: editor.getData({ rootName: version.id }),
    model: ''
  })
}
export function commitVersionData(editor: CK.Editor, version: VersionModel) {
  return version.commitData({
    view: editor.getData({ rootName: version.id }),
    model: ''
  })
}
