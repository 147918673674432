import { Box, BoxProps } from '@chakra-ui/react'

export const Dot = (props: BoxProps) => (
  <Box
    ml={1}
    color='primary.500'
    height='6px'
    width='6px'
    backgroundColor='primary.500'
    borderRadius='50%'
    fontSize='1px'
    {...props}
  >
    &nbsp;
  </Box>
)
