import { Icon, Flex, Text } from '@chakra-ui/react'
import { mdiAlertCircleOutline } from '@mdi/js'

const FormError = ({ error }: { error?: string }) => {
  return (
    <Flex alignItems='center' color='red' mt='2'>
      <Icon aria-label='error' mr='1'>
        <path d={mdiAlertCircleOutline} />
      </Icon>
      <Text variant='microcopy' color='red' fontSize='xs'>
        {error}
      </Text>
    </Flex>
  )
}
export default FormError
