import React, { ElementRef, useRef } from 'react'
import useConfirmation from './useConfirmation.js'
import { saveVersionData } from '../contexts/VersionContext.js'
import { useLibrary } from './useData.js'
import type { VersionModel } from '@sitecore-feaas/sdk'
import type Editor from '@sitecore-feaas/ckeditor5'
import { titleize } from 'inflection'
import { useNavigate } from 'react-router'

const useStylesUpToDateConfirmationAction = (
  action: 'stage' | 'publish',
  version?: VersionModel,
  editor?: Editor,
  overwriteActionCb?: () => void,
  versions?: VersionModel[]
) => {
  const library = useLibrary()
  const navigate = useNavigate()
  const stylesheet = useLibrary('stylesheets.first')
  const confirmCancelRef = useRef()
  const stylesUpToDate = stylesheet.isUpToDateWith('staged')

  const body = React.createElement(
    'div',
    null,
    React.createElement(
      'span',
      null,
      action === 'stage'
        ? 'Components staged in XM Cloud Pages may look different as the style library is not up-to-date. Consider '
        : 'Published components may look different as the style library is not up-to-date. Consider '
    ),
    React.createElement(
      'a',
      {
        onClick: () => {
          navigate(`${library.getPath()}/publishing/styles/${action}d`)
          ;(confirmCancelRef as { current: ElementRef<'button'> }).current.click()
        },
        style: { textDecoration: 'underline', cursor: 'pointer' }
      },
      action === 'stage' ? 'staging styles' : 'publishing styles'
    )
  )

  const handleStage = useConfirmation(
    async () => {
      if (overwriteActionCb) {
        overwriteActionCb()
      } else {
        saveVersionData(editor, version).stage()
        if (action === 'publish') version.publish()
      }
    },
    {
      title: `Styles are not up-to-date`,
      button: `${titleize(action)} components`,
      body,
      bypass: stylesUpToDate,
      variant: 'danger',
      cancelRef: confirmCancelRef
    },
    [editor, version, versions]
  )

  return handleStage
}

export default useStylesUpToDateConfirmationAction
