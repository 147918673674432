import { Select as ChakraSelect, CreatableSelect as ChakraCreatableSelect, Size } from 'chakra-react-select'

const chakraStyles = (size?: Size) => ({
  control: (base: any, state: any) => ({
    ...base,
    borderColor: 'border-color-a11y'
  }),
  option: (base: any, state: any) => ({
    ...base,
    transition: 'none !important',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    minWidth: '0',
    maxWidth: '100%',
    display: 'block',
    fontSize: 'md'
  }),
  menu: (base: any, state: any) => ({
    ...base,
    shadow: 'md',
    borderRadius: 'md',
    width: 'max-content',
    minWidth: '100%',
    overflow: 'hidden',
    zIndex: 3,
    cursor: 'pointer',
    caretColor: 'transparent'
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    background: 'transparent',
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    fontSize: '20px',
    px: size === 'sm' ? 1 : base.px
  }),
  indicatorSeparator: (base: any, state: any) => ({
    ...base,
    display: 'none'
  }),
  groupHeading: (base: any, state: any) => ({
    ...base,
    fontSize: '14px',
    fontWeight: 600,
    paddingLeft: '12px'
  })
})

const compactChakraStyles = (size?: Size) => ({
  ...chakraStyles(size),
  control: (base: any) => ({
    ...base,
    border: 0,
    color: 'blackAlpha.600',
    boxShadow: 'none !important' // hide outline on select focus
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    background: 'transparent',
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    padding: 0 // make dropwdon icon as small as possible
  }),
  singleValue: (base: any) => ({
    ...base,
    // Have the selected value control the width of the select
    position: 'initial',
    transform: 'none',
    maxWidth: 'none',
    cursor: 'default'
  }),
  valueContainer: (base: any) => ({
    ...base,
    flex: 'none',
    paddingRight: '0'
  }),
  menu: (base: any) => ({
    ...base,
    shadow: 'md',
    borderRadius: 'md',
    overflow: 'hidden',
    zIndex: 3,
    cursor: 'pointer',
    width: 'auto',
    mb: 0,
    mt: -2,
    left: -3
  }),
  option: (base: any, state: any) => ({
    ...base,
    // hide checkmark icons
    '> .chakra-menu__icon-wrapper': {
      display: 'none'
    }
  })
})

export const Select: typeof ChakraSelect = (props) => {
  return (
    <ChakraSelect
      {...props}
      chakraStyles={chakraStyles(props.size)}
      className='chakra-select'
      selectedOptionStyle='check'
      menuPosition='fixed'
    />
  )
}

export const CompactSelect: typeof ChakraSelect = (props) => {
  return (
    <ChakraSelect
      {...props}
      className='chakra-select'
      selectedOptionStyle='check'
      isSearchable={false}
      isClearable={false}
      chakraStyles={compactChakraStyles(props.size)}
    />
  )
}

export const CreatableSelect: typeof ChakraCreatableSelect = (props) => {
  return <ChakraCreatableSelect {...props} chakraStyles={chakraStyles(props.size)} className='chakra-select' />
}
