import type * as CK from '@sitecore-feaas/ckeditor5'
import { Style, VersionModel } from '@sitecore-feaas/sdk'
import { ContextType } from 'react'
import type { ChromeRefs } from '../components/editor/chrome/ChromeContext.js'
import type { PickerProps } from '../components/picker/Picker.js'
import { createContextProvider } from '../hooks/useContextProvider.js'
import { Measurement } from '../utils/dom.js'
import { Rect } from '../utils/rect.js'

export const [EditorContext, useEditorContextProvider] = createContextProvider(() => {
  return {
    editor: null as CK.Editor,
    editorClass: null as typeof CK.Editor,
    node: null as HTMLDivElement,
    root: null as HTMLElement,

    status: 'unloaded' as 'unloaded' | 'loading' | 'initialized' | 'ready',
    savingStatus: 'synchronized' as 'saving' | 'synchronized' | 'saved' | 'waiting',
    activeVersionId: null as string,
    isFocused: false,
    isDirty: false,
    isHiddenDisplayed: true,
    isDataDisplayed: true,
    isDataRepeated: true,
    isChromeVisible: false,
    isAutosaveEnabled: true,
    hasData: true,
    hasRepeating: true,
    isArchivedDisplayed: false,
    sidebarDialog: null as string,
    sidebarMode: null as PickerProps['mode'],
    current: null as CK.Editor['current'],
    context: null as Style.Context,

    //    addedElement: null as CK.ModelElement,

    blur: null as () => void,
    focus: null as (id?: string) => void,
    setActiveVersionId: null as (rootName?: string) => void,

    onChromeBeforeMeasure: null as (refs: ChromeRefs) => void,
    onChromeMeasureElement: (element: HTMLElement, rect?: Rect) => {
      if (rect) return rect
      const domRect = element.getBoundingClientRect()
      return {
        left: domRect.left,
        top: domRect.top,
        width: domRect.width,
        height: domRect.height
      }
    },
    onChromeMeasure: null as (measurements: Record<string, Measurement>, refs: ChromeRefs) => void,
    onChromePosition: null as (positions: Record<string, Rect>) => void,
    onContentChange: null as (content?: string) => void,

    onPlacementStart: null as (element: CK.ModelElement) => void,
    onVersionMove: null as (id: VersionModel['id'], before: VersionModel['id']) => void
  }
}, '__FEAASEditorContext')

export type EditorContext = ContextType<typeof EditorContext>[0]
export type EditorContextSetter = ContextType<typeof EditorContext>[1]
