import {
  mdiArrowExpand,
  mdiArrowExpandHorizontal,
  mdiBorderNoneVariant,
  mdiButtonPointer,
  mdiCalendar,
  mdiCardMultipleOutline,
  mdiCodeTags,
  mdiCreditCardChipOutline,
  mdiFileDocument,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatHeader4,
  mdiFormatHeader5,
  mdiFormatHeader6,
  mdiFormatListBulleted,
  mdiFormatParagraph,
  mdiFormatQuoteOpen,
  mdiImageOutline,
  mdiLink,
  mdiTable,
  mdiTextureBox,
  mdiToyBrick,
  mdiToyBrickOutline,
  mdiVariable,
  mdiVideoOutline,
  mdiViewDashboardOutline
} from '@mdi/js'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { Style } from '@sitecore-feaas/sdk'

const form =
  'M22 4H3a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5v-6A.5.5 0 0 0 22 4ZM14 13H3a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5Z'

export function getIconPathByElementName(type: string) {
  switch (type) {
    case '$root':
      return mdiFileDocument
    case 'paragraph':
      return mdiFormatParagraph
    case 'heading1':
      return mdiFormatHeader1
    case 'heading2':
      return mdiFormatHeader2
    case 'heading3':
      return mdiFormatHeader3
    case 'heading4':
      return mdiFormatHeader4
    case 'heading5':
      return mdiFormatHeader5
    case 'heading6':
      return mdiFormatHeader6
    case 'listItem':
      return mdiFormatListBulleted
    case 'card':
      return mdiCardMultipleOutline
    case 'section':
      return mdiViewDashboardOutline
    case 'container':
      return mdiBorderNoneVariant
    case 'blockquote':
      return mdiFormatQuoteOpen
    case 'button':
      return mdiButtonPointer
    case 'inline':
      return mdiCreditCardChipOutline
    case 'media':
    //return mdiMultimedia
    case 'image':
      return mdiImageOutline
    case 'video':
      return mdiVideoOutline
    case 'link':
      return mdiLink
    case 'var':
      return mdiVariable
    case 'table':
      return mdiTable
    case 'embed':
      return mdiCodeTags
    case 'component':
      return mdiToyBrickOutline
    case 'block':
      return mdiTextureBox
    case 'spacer':
      return mdiArrowExpandHorizontal
    case 'time':
      return mdiCalendar
    case 'form':
      return form
  }
}

export function getContextIcon(context: CK.ModelElement) {
  const definition = Style.Context.getDefinition(context)
  return getIconPathByElementName(definition.name)
}
