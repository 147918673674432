const purple = 'var(--chakra-colors-primary-300)'
import { Box, Icon, IconButton } from '@chakra-ui/react'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { mdiArrowUpDown, mdiChevronDown } from '@mdi/js'
import { cloneDeep, CSS, isDeepEquals, mergeDeep, SDKModel, Style, StylesheetModel } from '@sitecore-feaas/sdk'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import {
  Point,
  Rect,
  offsetRect,
  isPointInsideRectangle,
  getDistanceFromPointToPoint,
  getDistanceFromPointToLine,
  getLineSegmentCenter,
  getDistanceFromPointToRect
} from '../../../utils/rect.js'
import { useSDK } from '../../../hooks/useData.js'

type Line = [start: Point, end: Point]

const cursors = {
  ne: 'nesw-resize',
  sw: 'nesw-resize',
  se: 'nwse-resize',
  nw: 'nwse-resize',
  n: 'ns-resize',
  s: 'ns-resize',
  w: 'ew-resize',
  e: 'ew-resize',
  c: 'grab',
  add: 'cell'
}
type Anchors = {
  ne: Point
  nw: Point
  se: Point
  sw: Point
  c: Point
  n: Line
  s: Line
  w: Line
  e: Line
  add?: null
}
type Anchor = {
  index: number
  distance: number
  inside: boolean
  type: keyof Anchors
  point: Point
}
type Interaction = {
  anchor: Anchor
  point: Point
  type: 'resizing' | 'moving' | 'adding'
}
export interface Grid extends Style.Rule<'grid'> {}
export class Grid {
  constructor(rule?: Style.Rule<'grid'>) {
    if (rule) this.set(rule)
  }
  set(rule: Partial<Style.Rule<'grid'>>) {
    Object.assign(this, rule)
    return this
  }
  snapshotted: Grid
  snapshot() {
    this.snapshotted = this.clone()
    return this.snapshotted
  }

  clone(): Grid {
    return new Grid(cloneDeep(this))
  }

  static forSections(
    stylesheet: StylesheetModel,
    grids: Grid[],
    sections: CK.ModelElement[],
    customStyles: Style.Rule[]
  ) {
    return sections.map((section) => {
      const gridStyle =
        Style.Set.getContextElementAspect(stylesheet.rules, section, 'grid', customStyles) ||
        Style.Rule({ type: 'grid' })
      const grid = grids.find((grid) => grid.context == section) || new Grid()
      grid.set(gridStyle)
      grid.context = section
      return grid
    })
  }

  getHiddenProps(): string[] {
    return ['library', 'libraryId', 'stylesheet']
  }
  columnWidth: number
  offsetLeft: number
  offsetTop: number
  paddingTop: number
  paddingBottom: number
  paddingLeft: number
  paddingRight: number
  rows: number[] = []
  columns: number[] = []
  rowGap: number
  columnGap: number
  spacing: Style.Rule<'spacing'>
  outer: Rect
  inner: Rect

  rowHeight: number

  context: Style.Context
  commit(isFinal: boolean) {}
  render(isFinal: boolean) {}

  compute(rect: Rect, style: CSSStyleDeclaration, point: Point) {
    this.outer = rect
    this.offsetLeft = point.left
    this.offsetTop = point.top
    this.paddingLeft = parseFloat(style['paddingLeft'])
    this.paddingRight = parseFloat(style['paddingRight'])
    this.paddingTop = parseFloat(style['paddingTop'])
    this.paddingBottom = parseFloat(style['paddingBottom'])
    this.inner = {
      top: rect.top + this.paddingTop,
      left: rect.left + this.paddingLeft,
      width: rect.width - this.paddingRight - this.paddingLeft,
      height: rect.height - this.paddingBottom - this.paddingTop
    }
    this.columnGap = parseFloat(style['columnGap'])
    this.rowGap = parseFloat(style['rowGap'])
    this.rows = style['gridTemplateRows'].split(/\s/g).map((v) => parseFloat(v))
    this.columns = style['gridTemplateColumns'].split(/\s/g).map((v) => parseFloat(v))
  }

  getCell({ left, top }: Point, bias: Anchor['type'] = 'c'): Style.Grid.Cell {
    left -= this.paddingLeft - this.columnGap / 2
    top -= this.paddingTop - this.rowGap / 2
    if (bias.includes('n')) top++
    if (bias.includes('s')) top--
    if (bias.includes('w')) left++
    if (bias.includes('e')) left--
    for (var row = 0; row < this.rows.length; row++) {
      const oy = this.rowGap + this.rows[row]
      if (top > oy) {
        top -= oy
      } else {
        break
      }
    }
    for (var column = 0; column < this.columns.length; column++) {
      const ox = this.columnGap + this.columns[column]
      if (left > ox) {
        left -= ox
      } else {
        break
      }
    }

    column++
    row++

    return { column: Math.min(this.columns.length, column), row: Math.min(this.rows.length, row) }
  }

  getSpan(from: Point, to: Point) {
    to ||= from
    const min = {
      left: Math.min(from.left, to.left),
      top: Math.min(from.top, to.top)
    }
    const max = {
      left: Math.max(from.left, to.left),
      top: Math.max(from.top, to.top)
    }
    const m = this.getCell(max)
    return {
      from: this.getCell(min),
      to: {
        row: m.row + 1,
        column: m.column + 1
      }
    }
  }

  getColumnIndex(column: number) {
    if (column < 0) {
      return this.columns.length + column + 2
    }
    return column
  }
  getRowIndex(row: number) {
    if (row < 0) {
      return this.rows.length + row + 2
    }
    return row
  }

  getCellPoint(cell: Style.Grid.Cell) {
    const c = this.getColumnIndex(cell.column)
    const r = this.getRowIndex(cell.row)
    var left = this.paddingLeft
    var top = this.paddingTop
    for (var column = 0; column < Math.min(c - 1, this.columns.length); column++) {
      left += this.columnGap + this.columns[column]
    }
    for (var row = 0; row < Math.min(r - 1, this.rows.length); row++) {
      top += this.rowGap + this.rows[row] * Math.min(1, r - 1 - row)
    }
    return { top, left }
  }

  getSpanRect(from: Style.Grid.Cell, to: Style.Grid.Cell): Rect {
    const f = this.getCellPoint(from)
    const t = this.getCellPoint(to)
    return {
      ...f,
      width: t.left - f.left - this.columnGap,
      height: t.top - f.top - this.rowGap
    }
  }

  getItemsInCell(cell: Style.Grid.Cell) {
    return this.props.items.filter((item) => {
      const i = this.getNormalizedItem(item)
      return (
        i.from.column <= cell.column &&
        cell.column <= i.to.column - 1 &&
        i.from.row <= cell.row &&
        cell.row <= i.to.row - 1
      )
    })
  }

  getOverlappingItems(cell: Style.Grid.Item) {
    const c = this.getNormalizedItem(cell)
    return this.props.items.filter((item) => {
      const i = this.getNormalizedItem(item)
      if (
        c.from.column >= i.to.column ||
        c.to.column <= i.from.column ||
        c.from.row >= i.to.row ||
        c.to.row <= i.from.row
      ) {
        return false
      }
      return true
    })
  }

  getRectAnchors(rect: Rect): Anchors {
    return {
      nw: { top: rect.top, left: rect.left },
      ne: { top: rect.top, left: rect.left + rect.width },
      sw: { top: rect.top + rect.height, left: rect.left },
      se: { top: rect.top + rect.height, left: rect.left + rect.width },
      c: null, //{ top: rect.top + rect.height / 2, left: rect.left + rect.width / 2 },
      n: [
        { top: rect.top, left: rect.left },
        { top: rect.top, left: rect.left + rect.width }
      ],
      s: [
        { top: rect.top + rect.height, left: rect.left },
        { top: rect.top + rect.height, left: rect.left + rect.width }
      ],
      w: [
        { top: rect.top, left: rect.left },
        { top: rect.top + rect.height, left: rect.left }
      ],
      e: [
        { top: rect.top, left: rect.left + rect.width },
        { top: rect.top + rect.height, left: rect.left + rect.width }
      ]
    }
  }
  getClosestAnchor(point: Point, rect: Rect, target: 'edge' | 'corner' | null) {
    const anchors = this.getRectAnchors(rect)
    const inside = isPointInsideRectangle(point, rect)
    return Object.keys(anchors)
      .map((type: keyof Anchors) => {
        const anchor = anchors[type as keyof Anchors]
        if (!anchor) return
        if ('top' in anchor) {
          if (target && target != 'corner') return
          var distance = getDistanceFromPointToPoint(point, anchor)
          var p = anchor
        } else {
          if (target && target == 'corner') return
          var p = getLineSegmentCenter(anchor)
          var distance = getDistanceFromPointToLine(point, anchor)
        }
        return { type, distance, inside, point: p }
      })
      .filter(Boolean)
      .sort((a, b) => a.distance - b.distance)[0]
  }

  getAnchor(point: Point, preferIndex?: number) {
    const threshold = preferIndex == null ? 5 : 5
    const rects = this.props.items.map(({ from, to }) => this.getSpanRect(from, to))
    const corner = rects
      .map((anchor, index) => ({ index, ...this.getClosestAnchor(point, anchor, 'corner') }))
      .filter((anchor) => {
        return anchor.inside ? anchor.distance < threshold : anchor.distance < 5
      })
    const edge = rects
      .map((anchor, index) => ({ index, ...this.getClosestAnchor(point, anchor, 'edge') }))
      .filter((anchor) => {
        return anchor.inside ? anchor.distance < threshold : anchor.distance < 5
      })

    const match = rects
      .map((rect, index) => {
        return isPointInsideRectangle(point, rect)
          ? {
              index: index,
              type: 'c' as const,
              inside: true,
              point: point,
              // select smaller rect under the cursor
              distance: getDistanceFromPointToRect(point, rect) || rect.width * rect.height
              //getDistanceFromPointToPoint(point, { top: rect.top + rect.height / 2, left: rect.left + rect.width / 2 })
            }
          : null
      })
      .filter(Boolean)
    const groups = [corner, edge, match]
    const candidates = groups.flat().sort((a, b) => {
      return (
        Number(b.index == preferIndex) - Number(a.index == preferIndex) ||
        groups.findIndex((g) => g.includes(a)) - groups.findIndex((g) => g.includes(b)) ||
        a.distance - b.distance ||
        Number(b.inside) - Number(a.inside) ||
        getDistanceFromPointToRect(point, rects[a.index]) - getDistanceFromPointToRect(point, rects[b.index])
      )
    })

    return candidates[0]
  }

  filterAnchor(anchor: Anchor, preferIndex: number) {
    if (preferIndex != null && (anchor?.type == 'c' || anchor?.type == 'add')) return null
    return anchor
  }

  getDenormalizedItem(item: Style.Grid.Item): Style.Grid.Item {
    const center = Math.floor((this.props.rows.length - 1) / 2)
    return {
      ...item,
      to: {
        column: item.to.column,
        row: item.to.row > center ? item.to.row - this.props.rows.length - 2 : item.to.row
      }
    }
  }
  getNormalizedItem(old: Style.Grid.Item) {
    return {
      ...old,
      ...this.getNormalizedSpan(old)
    }
  }
  getNormalizedSpan(old: Style.Grid.CellSpan) {
    const from = {
      column: Math.min(old.from.column, old.to.column),
      row: Math.min(old.from.row, old.to.row)
    }
    const to = {
      column: Math.max(old.from.column, old.to.column),
      row: Math.max(old.from.row, old.to.row)
    }
    return {
      from: {
        column: this.getColumnIndex(from.column),
        row: this.getRowIndex(from.row)
      },
      to: {
        column: this.getColumnIndex(to.column),
        row: this.getRowIndex(to.row)
      }
    }
  }

  clipPoint(point: Point) {
    return {
      left: Math.max(0, Math.min(this.outer.width, point.left - this.outer.left)),
      top: Math.max(0, Math.min(this.outer.height, point.top - this.outer.top))
    }
  }

  interacting = {} as Interaction

  interact(interaction: Partial<typeof this.interacting>) {
    Object.assign(this.interacting, interaction)
    return true
  }

  hoveredAnchor: Anchor
  preventAdding: boolean

  matchAnchor(point: Point, preferIndex?: number) {
    return this.filterAnchor(
      this.getAnchor(this.clipPoint(point), preferIndex) ||
        (isPointInsideRectangle(point, this.inner) && !this.preventAdding
          ? {
              type: 'add',
              index: this.props.items.length,
              distance: 0,
              inside: false,
              point: null
            }
          : null),
      preferIndex
    )
  }

  recognize(point: Point, preferIndex?: number) {
    if (this.interacting.anchor) return this
    if (getDistanceFromPointToPoint(point, this.interacting.point) < 20) {
      return
    }
    if (this.interacting.type == 'resizing') {
      this.interacting.anchor = {
        type: 'n',
        point: null,
        index: null,
        distance: 0,
        inside: true
      }
    } else {
      this.interacting.anchor = this.matchAnchor(this.interacting.point, preferIndex)
      if (!this.interacting.anchor) return
    }
    this.snapshot()
    this.rowHeight = this.getRowHeight()
    return this.set({
      props: {
        ...this.props,
        rows: this.rows.map((m, i) => ({
          min: { unit: 'px' as const, value: this.rows[i] },
          max: { unit: 'px' as const, value: this.rows[i] }
        }))
      }
    })
  }

  getRowHeight() {
    return (this.props.rows.find((r) => !this.isRowExpandable(r)) || this.props.rows[0])?.min?.value ?? 24
  }

  modify(point: Point) {
    var props = JSON.stringify(this.props)
    if (this.interacting.type == 'resizing') {
      const delta = this.interacting.point.top - point.top
      const rowHeight = this.getRowHeight() + this.rowGap
      const deltaRows = -Math.floor(delta / rowHeight)
      this.setRowNumber(this.snapshotted.props.rows.length + deltaRows)
    } else {
      if (this.interacting.anchor?.type == 'add') {
        this.addItem(this.clipPoint(point), this.clipPoint(this.interacting.point))
      } else {
        this.resizeItem(this.clipPoint(point), this.clipPoint(this.interacting.point), this.interacting.anchor)
      }
    }
    return JSON.stringify(this.props) != props
  }

  isRowExpandable(row: Style.Grid.CellSize) {
    return row.max == null || row.max.unit == 'fr'
  }

  modifiedRows: number[] = []
  setRowNumber(rowNumber: number, snapshot = this.snapshotted || this) {
    const deltaRows = rowNumber - snapshot.props.rows.length
    const rowHeight = this.getRowHeight()
    const centerRow = (snapshot.props.rows.length - 1) / 2 + 1
    const before = snapshot.props.rows.slice(0, centerRow)
    const after = snapshot.props.rows.slice(centerRow)
    const middle: Style.Grid.CellSize[] = []
    const items = snapshot.props.items.map((item) => this.getNormalizedItem(item))

    const centeredExpansion = [Math.floor(centerRow), Math.ceil(centerRow)].some((index) => {
      return this.isRowExpandable(snapshot.props.rows[index - 1])
    })
    this.modifiedRows = []
    const delta = Math.max(-(snapshot.props.rows.length - 1), deltaRows)
    if (deltaRows > 0) {
      for (var i = 0; i < deltaRows; i++) {
        this.modifiedRows.push(before.length + i)
        middle.push(
          Style.Grid.CellSize({
            min: rowHeight,
            max: rowHeight
          })
        )
      }
    } else if (deltaRows < 0) {
      const removedAbove = Math.floor(-delta / 2)
      const removedBelow = Math.ceil(-delta / 2)
      before.splice(before.length - removedAbove)
      after.splice(0, removedBelow)
    }
    for (var item of items) {
      if (item.from.row > centerRow) {
        item.from.row += delta
      }
      if (item.to.row >= centerRow + 1) {
        item.to.row += delta
      }
      item.from.row = Math.max(1, Math.min(snapshot.props.rows.length + delta - 1, item.from.row))
      item.to.row = Math.max(item.from.row + 1, Math.min(snapshot.props.rows.length + delta + 1, item.to.row))
    }
    const rows = before.concat(middle).concat(after)

    if (centeredExpansion) {
      for (var r = 0; r < rows.length; r++) {
        const row = { ...rows[r] }
        if (this.isRowExpandable(row)) row.max = { unit: row.min.unit, value: row.min.value }
        if (r == Math.floor(rows.length / 2)) {
          row.max = null
        }
        rows[r] = row
      }
    }

    return this.set({
      props: {
        ...this.props,
        items: this.props.items.map((item, index) =>
          this.getNormalizedItem({
            order: items[index].order,
            from: {
              column: item.from.column,
              row: items[index].from.row
            },
            to: {
              column: item.to.column,
              row: items[index].to.row
            }
          })
        ),
        rows: rows
      }
    })
  }

  setRowHeight(length: Style.Length) {
    return this.set({
      props: {
        ...this.props,
        rows: this.props.rows.map((r) => ({
          min: length,
          max: this.isRowExpandable(r) ? r.max : length
        }))
      }
    })
  }

  modifiedColumns: number[] = []
  setColumnNumber(columnNumber: number, snapshot = this.snapshotted || this) {
    const deltaColumns = columnNumber - snapshot.props.columns.length
    const centerColumn = (snapshot.props.columns.length - 1) / 2 + 1
    const before = snapshot.props.columns.slice(0, centerColumn)
    const after = snapshot.props.columns.slice(centerColumn)
    const middle: Style.Grid.CellSize[] = []
    const items = snapshot.props.items.map((item) => this.getNormalizedItem(item))
    this.modifiedColumns = []
    const delta = Math.max(-(snapshot.props.columns.length - 1), deltaColumns)
    if (deltaColumns > 0) {
      for (var i = 0; i < deltaColumns; i++) {
        this.modifiedColumns.push(before.length + i)
        middle.push(
          Style.Grid.CellSize({
            min: 0,
            max: { unit: 'fr', value: 1 }
          })
        )
      }
    } else if (deltaColumns < 0) {
      const removedAbove = Math.floor(-delta / 2)
      const removedBelow = Math.ceil(-delta / 2)
      before.splice(before.length - removedAbove)
      after.splice(0, removedBelow)
    }
    for (var item of items) {
      if (item.from.column > centerColumn) {
        item.from.column += delta
      }
      if (item.to.column >= centerColumn + 1) {
        item.to.column += delta
      }
      item.from.column = Math.max(1, Math.min(snapshot.props.columns.length + delta - 1, item.from.column))
      item.to.column = Math.max(
        item.from.column + 1,
        Math.min(snapshot.props.columns.length + delta + 1, item.to.column)
      )
    }
    const columns = before.concat(middle).concat(after)

    return this.set({
      props: {
        ...this.props,
        items: this.props.items.map((item, index) =>
          this.getNormalizedItem({
            order: items[index].order,
            from: {
              column: items[index].from.column,
              row: item.from.row
            },
            to: {
              column: items[index].to.column,
              row: item.to.row
            }
          })
        ),
        columns: columns
      }
    })
  }

  getExpandableRows() {
    return (
      !this.interacting.type || this.interacting.type == 'resizing' || (this.interacting.type && !this.snapshotted)
        ? this.props.rows
        : this.snapshotted.props.rows
    )
      .map((r, i) => {
        return this.isRowExpandable(r) ? i : null
      })
      .filter((i) => i != null)
  }

  addItem(point: Point, start: Point) {
    const item = {
      ...this.getSpan(point, start),
      order: 0
    }
    const index =
      this.snapshotted.props.items.length == this.props.items.length
        ? this.props.items.length
        : this.props.items.length - 1
    return this.set({
      props: {
        ...this.props,
        items: this.snapshotted.props.items
          .slice(0, index)
          .concat(item)
          .map((item) => {
            return this.getNormalizedItem(item)
          })
      }
    })
  }

  resizeItem(point: Point, start: Point, anchor: Anchor) {
    const old = this.getNormalizedItem(this.snapshotted.props.items[anchor.index])
    const from = this.getCell(anchor.point, anchor.type)
    const to = this.getCell(point, anchor.type)
    const delta = { column: to.column - from.column, row: to.row - from.row }
    var item = old

    const dir = anchor.type

    for (var c = 0; c != delta.column; ) {
      const nc = c + (delta.column > 0 ? 1 : -1)
      if (
        (old.to.column + nc == old.from.column && dir.match(/e/)) ||
        (old.to.column == old.from.column + nc && dir.match(/w/)) ||
        (old.from.column + nc < 1 && !dir.includes('e')) ||
        (old.to.column + nc > this.props.columns.length + 1 && !dir.includes('w'))
      )
        break
      c = nc
    }
    for (var r = 0; r != delta.row; ) {
      const nr = r + (delta.row > 0 ? 1 : -1)
      if (
        (old.to.row + nr == old.from.row && dir.match(/s/)) ||
        (old.to.row == old.from.row + nr && dir.match(/n/)) ||
        (old.from.row + nr < 1 && !dir.includes('s')) ||
        (old.to.row + nr > this.props.rows.length + 1 && !dir.includes('n'))
      )
        break
      r = nr
    }

    if (dir == 'c' || dir == 'n' || dir == 'nw' || dir == 'ne') {
      item.from.row += r
    }
    if (dir == 'c' || dir == 's' || dir == 'sw' || dir == 'se') {
      item.to.row += r
    }
    if (dir == 'c' || dir == 'e' || dir == 'ne' || dir == 'se') {
      item.to.column += c
    }
    if (dir == 'c' || dir == 'w' || dir == 'nw' || dir == 'sw') {
      item.from.column += c
    }
    return this.set({
      props: {
        ...this.props,
        items: this.snapshotted.props.items
          .slice(0, Math.max(0, anchor.index))
          .concat(item)
          .concat(this.snapshotted.props.items.slice(anchor.index + 1))
      }
    })
  }

  reset() {
    this.interacting = {} as Interaction
  }

  abort() {
    this.reset()
  }

  finalize() {
    this.modifiedRows = []
    const expandableRows = this.getExpandableRows()
    return this.set({
      props: {
        ...this.props,
        rows: this.props.rows.map((row, r) => {
          const isEmpty = this.props.columns.every((col, c) => {
            return this.getItemsInCell({ row: r + 1, column: c + 1 }).length == 0
          })
          const isExpandable = expandableRows.includes(r)
          return {
            min: {
              value: this.rowHeight,
              unit: 'px'
            },
            max: isExpandable
              ? null /*(isEmpty ? { unit: 'fr', value: 0.000001 } : { unit: 'fr', value: 1 })*/
              : row.max
          }
        })
      }
    })
  }

  getCacheChecksum() {
    return JSON.stringify([
      this.props.items,
      this.columns,
      this.rows,
      this.rowGap,
      this.columnGap,
      this.outer,
      this.inner,
      this.props.rows
    ])
  }
}

function Span({
  grid,
  from,
  to,
  offset = 0,
  corners,
  isBackground,
  isHovered: isHovered,
  id,
  ...props
}: Style.Grid.CellSpan & {
  isHovered: boolean
  isBackground: boolean
  grid: Grid
  corners: string[]
  offset?: number
} & Record<string, any>) {
  const rect = offsetRect(grid.getSpanRect(from, to), offset)
  const c = 6
  const p = 12
  return (
    <>
      <mask id={`feaas-grid-box-${id}`}>
        <rect
          stroke='white'
          width={rect.width + p}
          height={rect.height + p}
          x={-p / 2 + rect.left}
          y={-p / 2 + rect.top}
          fill='white'
        />

        <rect
          fillOpacity={corners.includes('nw') ? '1' : '0'}
          width={p}
          height={p}
          x={rect.left - p / 2}
          y={rect.top - p / 2}
        />

        <rect
          fillOpacity={corners.includes('se') ? '1' : '0'}
          width={p}
          height={p}
          x={rect.left + rect.width - p / 2}
          y={rect.top + rect.height - p / 2}
        />

        <rect
          fillOpacity={corners.includes('ne') ? '1' : '0'}
          width={p}
          height={p}
          x={rect.left + rect.width - p / 2}
          y={rect.top - p / 2}
        />
        <rect
          fillOpacity={corners.includes('sw') ? '1' : '0'}
          width={p}
          height={p}
          x={rect.left - p / 2}
          y={rect.top + rect.height - p / 2}
        />
      </mask>
      <g mask={`url(#feaas-grid-box-${id})`}>
        <rect
          width={rect.width}
          height={rect.height}
          fill='none'
          rx={6}
          ry={6}
          x={rect.left}
          y={rect.top}
          strokeDasharray={4}
          strokeDashoffset={4}
          strokeWidth={1}
          strokeOpacity={!isHovered ? 1 : 0}
          stroke={'rgba(255,255,255,0.2)'}
        ></rect>
        <rect
          width={rect.width}
          height={rect.height}
          fill='none'
          rx={6}
          ry={6}
          x={rect.left}
          y={rect.top}
          strokeDasharray={4}
          strokeWidth={1}
          strokeOpacity={!isHovered ? 1 : 0}
          stroke={isHovered ? purple : isBackground ? 'rgba(0,0,0,0.6)' : 'rgba(0,0,0,0.3)'}
        ></rect>
        <rect
          width={rect.width}
          height={rect.height}
          fill='none'
          rx={6}
          ry={6}
          x={rect.left}
          y={rect.top}
          strokeWidth={2.25}
          strokeOpacity={isHovered ? 1 : 0}
          stroke={'var(--chakra-colors-primary-500)'}
          {...props}
        ></rect>
        <rect
          width={rect.width}
          height={rect.height}
          fill='none'
          rx={6}
          ry={6}
          x={rect.left}
          y={rect.top}
          strokeWidth={1.5}
          strokeOpacity={isHovered ? 1 : 0}
          stroke={purple}
          {...props}
        ></rect>
      </g>
      <>
        <rect
          width={c}
          height={c}
          fill='none'
          stroke={'var(--chakra-colors-primary-500)'}
          strokeWidth={2.25}
          x={rect.left - c / 2}
          y={rect.top - c / 2}
          strokeOpacity={corners.includes('nw') || corners.includes('n') || corners.includes('w') ? 1 : 0}
        />
        <rect
          width={c}
          height={c}
          fill='none'
          stroke={purple}
          strokeWidth={1.5}
          x={rect.left - c / 2}
          y={rect.top - c / 2}
          strokeOpacity={corners.includes('nw') || corners.includes('n') || corners.includes('w') ? 1 : 0}
        />
        <rect
          width={c}
          height={c}
          fill='none'
          stroke={'var(--chakra-colors-primary-500)'}
          strokeWidth={2.25}
          x={rect.left + rect.width - c / 2}
          y={rect.top + rect.height - c / 2}
          strokeOpacity={corners.includes('se') || corners.includes('e') || corners.includes('s') ? 1 : 0}
        />
        <rect
          width={c}
          height={c}
          fill='none'
          stroke={purple}
          strokeWidth={1.5}
          x={rect.left + rect.width - c / 2}
          y={rect.top + rect.height - c / 2}
          strokeOpacity={corners.includes('se') || corners.includes('e') || corners.includes('s') ? 1 : 0}
        />
        <rect
          width={c}
          height={c}
          fill='none'
          stroke={'var(--chakra-colors-primary-500)'}
          strokeWidth={2.25}
          x={rect.left + rect.width - c / 2}
          y={rect.top - c / 2}
          strokeOpacity={corners.includes('ne') || corners.includes('n') || corners.includes('e') ? 1 : 0}
        />
        <rect
          width={c}
          height={c}
          fill='none'
          stroke={purple}
          strokeWidth={1.5}
          x={rect.left + rect.width - c / 2}
          y={rect.top - c / 2}
          strokeOpacity={corners.includes('ne') || corners.includes('n') || corners.includes('e') ? 1 : 0}
        />
        <rect
          width={c}
          height={c}
          fill='none'
          stroke={'var(--chakra-colors-primary-500)'}
          strokeWidth={2.25}
          x={rect.left - c / 2}
          y={rect.top + rect.height - c / 2}
          strokeOpacity={corners.includes('sw') || corners.includes('s') || corners.includes('w') ? 1 : 0}
        />
        <rect
          width={c}
          height={c}
          fill='none'
          stroke={purple}
          strokeWidth={1.5}
          x={rect.left - c / 2}
          y={rect.top + rect.height - c / 2}
          strokeOpacity={corners.includes('sw') || corners.includes('s') || corners.includes('w') ? 1 : 0}
        />
      </>
    </>
  )
}
export function ChromeGrid({
  grid: givenGrid,
  activeIndex,
  index,
  isVisible,
  isActive,
  isDisabled,
  pointer
}: {
  index?: number
  activeIndex?: number
  grid: Grid
  isVisible: boolean
  isActive: boolean
  isDisabled: boolean
  pointer?: Point
}) {
  const sdk = useSDK()
  const [, setAnchor] = useState<Anchor>(null)
  const [hovered, setHovered] = useState<Style.Grid.Cell>(null)
  const ref = useRef<{ grid: Grid; activeIndex: number }>({
    grid: null,
    activeIndex: null
  })
  if (givenGrid) ref.current.grid = givenGrid
  const grid = ref.current.grid
  ref.current.activeIndex = activeIndex

  const interaction = grid?.interacting
  const currentAnchor = grid?.interacting.anchor || grid?.hoveredAnchor
  const cursor = isVisible
    ? interaction.type == 'moving' && interaction.anchor?.type == 'c'
      ? 'grabbing'
      : currentAnchor
      ? cursors[currentAnchor.type as keyof typeof cursors]
      : ''
    : ''

  const currentIndex = currentAnchor?.index
  const offset = 1.5
  const allCorners = ['ne', 'nw', 'se', 'sw']

  sdk.log('grid rendered', grid, grid.interacting, isDisabled)

  useEffect(() => {
    var timeout: ReturnType<typeof setTimeout>
    function recognizeAnchor(pointer: Point) {
      const grid = ref.current.grid
      if (!grid.inner) return
      if (!grid.interacting.type) {
        const isInside = isPointInsideRectangle(pointer, offsetRect(grid.outer, -10))
        const hoveredAnchor = isInside ? grid.matchAnchor(pointer, ref.current.activeIndex) : null
        if (grid.hoveredAnchor?.type != hoveredAnchor?.type || grid.hoveredAnchor?.index != hoveredAnchor?.index)
          grid.hoveredAnchor = hoveredAnchor
        const hoveredCell = !grid.interacting.anchor && isInside ? grid.getCell(grid.clipPoint(pointer)) : null

        if (isActive) setHovered((hovered) => (isDeepEquals(hoveredCell, hovered) ? hovered : hoveredCell))
        setAnchor((anchor) => (isDeepEquals(grid.hoveredAnchor, anchor) ? anchor : grid.hoveredAnchor))
      }
    }
    const onPointerDown = ({ target, clientX: left, clientY: top }: PointerEvent) => {
      clearTimeout(timeout)
      if ((target as HTMLElement).closest('.ui')) return
      recognizeAnchor(pointer || { top, left })
    }
    const onPointerMove = ({ clientX: left, clientY: top }: PointerEvent) => {
      clearTimeout(timeout)
      timeout = setTimeout(
        () => {
          recognizeAnchor(pointer || { top, left })
        },
        grid.interacting.type ? 1 : 32
      )
    }
    const onPointerUp = (e: PointerEvent) => {
      setTimeout(() => onPointerMove(e), 100)
    }
    const onSelectStart = (e: Event) => {
      const grid = ref.current.grid
      const target = e.target as HTMLElement
      if ((grid.hoveredAnchor || grid.interacting.anchor) && ref.current.activeIndex != null) {
        console.log('prevent selection because of anchor', e, grid.hoveredAnchor)
        e.preventDefault()
      }
    }
    const onDragStart = (e: Event) => {
      const grid = ref.current.grid
      if (grid.hoveredAnchor) {
        console.log('prevent drag because of anchor', grid.hoveredAnchor)
        e.preventDefault()
      }
    }
    clearTimeout(timeout)
    if (!isDisabled) {
      document.addEventListener('pointermove', onPointerMove)
      document.addEventListener('pointerdown', onPointerDown, { capture: true })
      document.addEventListener('pointerup', onPointerUp)
      document.addEventListener('selectstart', onSelectStart, { capture: true })
      document.addEventListener('selectionchange', onSelectStart, { capture: true })
      document.addEventListener('dragstart', onDragStart, { capture: true })
    }
    return () => {
      setHovered(null)
      setAnchor(null)
      document.removeEventListener('pointermove', onPointerMove)
      document.removeEventListener('pointerdown', onPointerDown, { capture: true })
      document.removeEventListener('pointerup', onPointerUp)
      document.removeEventListener('selectstart', onSelectStart, { capture: true })
      document.removeEventListener('selectionchange', onSelectStart, { capture: true })
      document.removeEventListener('dragstart', onDragStart, { capture: true })
    }
  }, [isActive, isDisabled, activeIndex])

  useEffect(() => {
    document.body.style.cursor = cursor
    return () => {
      document.body.style.cursor = ''
    }
  }, [isActive, cursor])

  const button = useMemo(() => {
    return (
      <IconButton
        data-index={index}
        className='resize-grid'
        pointerEvents={'all'}
        variant={'primary'}
        aria-label='Resize grid'
        size='xs'
        position={'absolute'}
        zIndex={6}
        left={'50%'}
        top='100%'
        transform={'translate(-50%, -50%) translateY(-10px)'}
        icon={
          <Icon boxSize='icon-lg'>
            <path d={mdiArrowUpDown} />
          </Icon>
        }
      />
    )
  }, [])

  return useMemo(() => {
    if (!grid || !grid.outer) return

    const boxes = grid.props.items

    const strokeWidth = grid.rowGap == 0 || grid.columnGap == 0 ? 0.5 : 1

    const middleLine = grid.getCellPoint({ row: grid.rows.length / 2 + 1, column: 0 })
    var y = grid.paddingTop
    const expandableRows = grid.getExpandableRows()
    const cells = grid.rows.map((height, r) => {
      const oy = height + grid.rowGap
      y += oy
      var x = grid.paddingLeft
      return grid.columns.map((width, c) => {
        const ox = width + grid.columnGap
        x += ox
        return (
          <rect
            key={r + '-' + c}
            width={width - strokeWidth}
            height={height - strokeWidth}
            rx={grid.rowGap == 0 || grid.columnGap == 0 ? 0 : 4}
            ry={grid.rowGap == 0 || grid.columnGap == 0 ? 0 : 4}
            x={x - ox - strokeWidth / 2}
            y={y - oy - strokeWidth / 2}
            opacity={grid.getItemsInCell({ column: c + 1, row: r + 1 }).length == 0 ? 1 : grid.interacting ? 0.7 : 0}
            fill={
              grid.interacting.type == 'resizing' && grid.modifiedRows.includes(r)
                ? 'rgba(180,220,180,0.5)'
                : grid.hoveredAnchor?.type == 'add' && hovered?.column == c + 1 && hovered?.row == r + 1
                ? `rgba(0,0,0,0.03)`
                : expandableRows.includes(r)
                ? 'rgba(148, 148, 157, 0.06)'
                : 'rgba(200,200,200,0.02)'
            }
            stroke='rgba(0,0,0,0.1)'
            strokeWidth={strokeWidth}
          />
        )
      })
    })
    return (
      <>
        <div
          className='chrome-grid'
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            transform: `translate(${-5 + grid.offsetLeft}px, ${-5 + grid.offsetTop}px)`,
            //clipPath: `inset(${Math.max(0, -grid.offsetTop)}px 0 0 0)`,
            //background: 'red',
            pointerEvents: 'none',
            width: grid.outer.width + 10 + 'px',
            height: grid.outer.height + 10 + 'px'
          }}
        >
          <style>{`.chrome-grid rect.hovered, .chrome-grid rect { transition: stroke-opacity .2s, stroke .2s, fill-opacity .2s, opacity .2s;; }
          .chrome-grid g { transition: opacity .2s; }`}</style>
          <svg width={grid.outer.width + 10} height={grid.outer.height + 10} style={{ overflow: 'visible' }}>
            <g transform='translate(5, 5)' opacity={isActive || grid.interacting.anchor ? 1 : 0}>
              {cells}
            </g>
            <g transform='translate(5, 5)'>
              {boxes
                .slice()
                .sort((a, b) => {
                  return Number(currentIndex == boxes.indexOf(a)) - Number(currentIndex == boxes.indexOf(b))
                })
                .map((b) => {
                  const i = boxes.indexOf(b)
                  return (
                    <Span
                      {...b}
                      offset={-offset}
                      grid={grid}
                      corners={
                        activeIndex != null
                          ? grid.hoveredAnchor?.index == i && grid.hoveredAnchor?.type != 'c'
                            ? allCorners
                            : []
                          : !grid.interacting.anchor
                          ? grid.hoveredAnchor?.index == i
                            ? allCorners
                            : []
                          : grid.interacting.anchor.index == i
                          ? allCorners.filter((c) => c.includes(grid.interacting.anchor.type))
                          : []
                      }
                      id={index + '-' + i}
                      key={i}
                      isBackground={grid.interacting.anchor?.index != i}
                      isHovered={currentIndex == i}
                    />
                  )
                })}
              {grid.interacting.type == 'resizing' && grid.modifiedRows.length == 0 && (
                <rect
                  x='0'
                  y={
                    grid.modifiedRows.length == 0
                      ? middleLine.top
                      : grid.getCellPoint({ column: 1, row: grid.modifiedRows[0] + 1 }).top - grid.rowGap / 2
                  }
                  width='100%'
                  height={Math.max(
                    2,
                    grid.getCellPoint({ column: 1, row: grid.modifiedRows[grid.modifiedRows.length - 1] + 1 }).top -
                      grid.getCellPoint({ column: 1, row: grid.modifiedRows[0] }).top
                  )}
                  fill={grid.modifiedRows.length == 0 ? `rgba(200,130,130,.5)` : `rgba(130,200,130,.2)`}
                />
              )}
            </g>
          </svg>
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              transform: `translateY(5px)`,
              opacity: isActive ? 1 : 0,
              transition: 'opacity 0.2s'
            }}
          >
            {button}
          </div>
        </div>
      </>
    )
  }, [grid.hoveredAnchor?.type, grid.hoveredAnchor?.index, isActive, currentIndex, grid.getCacheChecksum()])
}
