import { forwardRef, MutableRefObject, ReactElement, ReactNode } from 'react'
import { Box, Flex, FormControl, FormControlProps, FormLabel } from '@chakra-ui/react'
import { Dot } from './Dot.js'
import FormError from './FormError.js'

const FieldsetField = forwardRef(
  (
    {
      children,
      label,
      isChanged,
      required,
      htmlFor,
      isDisabled,
      error,
      extraProps,
      className,
      labelProps,
      ...props
    }: {
      label?: string | ReactElement
      isChanged?: boolean
      required?: boolean
      children?: ReactNode
      htmlFor?: string
      isDisabled?: boolean
      error?: string
      className?: string
      labelProps?: { [key: string]: any }
      extraProps?: { [key: string]: any }
    } & Omit<FormControlProps, 'label'>,
    ref: MutableRefObject<HTMLDivElement>
  ) => (
    <FormControl {...props} isDisabled={isDisabled} ref={ref} {...extraProps} className={className}>
      {extraProps?.labelRight && children}

      {(label || required || isChanged) && (
        <FormLabel
          htmlFor={htmlFor}
          mx={0}
          w='full'
          whiteSpace={'nowrap'}
          fontSize='13px'
          fontWeight='normal'
          color='blackAlpha.500'
          {...labelProps}
        >
          <Flex alignItems='center' data-modified={isChanged}>
            {label}

            {required && (
              <Box display='inline' color='red' ml='1'>
                *
              </Box>
            )}

            {isChanged && <Dot />}
          </Flex>
        </FormLabel>
      )}

      {!extraProps?.labelRight && children}

      {error && <FormError error={error} />}
    </FormControl>
  )
)

export default FieldsetField
