import { ButtonGroup, ButtonGroupProps } from '@chakra-ui/react'
import { Children, cloneElement, ReactElement, ReactNode } from 'react'

interface Props<T> {
  value?: T
  onChange: (index: T) => void
  activeProps?: any
  children?: ReactNode
  inactiveProps?: any
}

const ButtonGroupSwitch = <T extends unknown>(props: Props<T> & Omit<ButtonGroupProps, 'onChange'>) => {
  const {
    value,
    onChange,
    activeProps = { bg: 'primary.100', variant: 'subtle' },
    inactiveProps = { variant: 'minimal' },
    ...more
  } = props

  return (
    <ButtonGroup
      p={1}
      bg='white'
      border='1px solid transparent'
      borderColor='blackAlpha.200'
      borderRadius='100'
      className='button-group'
      spacing={0}
      {...more}
    >
      {Children.map(props.children, (child, i) => {
        const reactChild = child as ReactElement
        function getValue() {
          return 'value' in reactChild.props ? reactChild.props.value : i
        }
        return (
          reactChild &&
          cloneElement(
            reactChild,
            getValue() === value
              ? { onClick: () => onChange(getValue()), ...activeProps, 'data-activated': true }
              : { onClick: () => onChange(getValue()), ...inactiveProps }
          )
        )
      })}
    </ButtonGroup>
  )
}

export default ButtonGroupSwitch
