import { intersectRect, Rect } from './rect.js'

export function getActiveElement(doc: Document | ShadowRoot = document): HTMLElement {
  if (doc.activeElement.shadowRoot) {
    return getActiveElement(doc.activeElement.shadowRoot)
  } else {
    return doc.activeElement as HTMLElement
  }
}

var willScroll: ReturnType<typeof setTimeout>
export function scrollForTogglingCard(element: HTMLElement) {
  clearTimeout(willScroll)

  const wrapper = document.querySelector('#overflow-wrapper') as HTMLElement
  const modalContent = document.querySelector('#entity-modal-body-wrapper')
  if (!wrapper) return
  const footer = document.querySelector('#fixed-footer') as HTMLElement

  const expansion = element.parentNode.querySelector('.entity-overlay-lower, .entity-overlay-form') as HTMLElement
  const entity = (expansion as HTMLElement) || element
  const scrollOffset = 24
  const headerHeight = 60
  const footerHeight = footer?.offsetHeight || 0
  const expansionBottom = entity.getBoundingClientRect().bottom
  const scrollableBottom =
    wrapper.getBoundingClientRect().bottom - (parseInt(window.getComputedStyle(wrapper).paddingBottom) || 0)
  const overflow = expansionBottom + scrollOffset - scrollableBottom
  const target = expansion || element
  const targetHeight = target.getBoundingClientRect().height
  const targetBottom = target.getBoundingClientRect().bottom
  const screenBottom = modalContent ? modalContent.getBoundingClientRect().bottom : window.innerHeight
  wrapper.style.transition = 'none'
  wrapper.style.paddingBottom = Math.max(0, overflow) + 'px'
  if (expansion) {
    const el = document.createElement('div')
    el.className = 'spacer'
    el.style.position = 'absolute'
    el.style.top = '100%'
    el.style.width = scrollOffset + 'px'
    el.style.height = scrollOffset + 'px'
    expansion.appendChild(el)
  }

  if (element.getBoundingClientRect().top < headerHeight || targetHeight > window.innerHeight - headerHeight) {
    element.style.scrollMargin = headerHeight + scrollOffset + 'px'
    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    })
  } else if (targetBottom > screenBottom) {
    target.style.scrollMargin = scrollOffset + (modalContent ? 0 : footerHeight) + 'px'
    target.scrollIntoView({
      block: 'end',
      behavior: 'smooth'
    })
  }
  return () => {
    expansion?.querySelector('.spacer')?.remove()
    wrapper.style.transition = ''
    wrapper.style.paddingBottom = '0px'
    willScroll = setTimeout(() => {
      // hack to make browser re-set its scroll position target.
      // otherwise if page shrinks, browser still considers its position past the limit
      document.getElementById('root').scrollBy({
        top: -0.01
      })
    }, 500)
  }
}

export function isInDocument(element: ParentNode) {
  var currentElement = element
  while (currentElement && currentElement.parentNode) {
    const parent = currentElement.parentNode
    if (parent.nodeType == Node.DOCUMENT_NODE) {
      return true
    } else if (parent instanceof ShadowRoot) {
      currentElement = parent.host
    } else {
      currentElement = parent
    }
  }
  return false
}

export function documentPositionComparator(a: HTMLElement, b: HTMLElement) {
  if (a === b) {
    return 0
  }
  var position = a.compareDocumentPosition(b)
  if (position & Node.DOCUMENT_POSITION_FOLLOWING || position & Node.DOCUMENT_POSITION_CONTAINED_BY) {
    return -1
  } else if (position & Node.DOCUMENT_POSITION_PRECEDING || position & Node.DOCUMENT_POSITION_CONTAINS) {
    return 1
  } else {
    return 0
  }
}

export function getClippedRectangle(
  element: HTMLElement,
  getBoundingClientRect: (element: HTMLElement, rect?: Rect) => Rect,
  box = getBoundingClientRect(element) as Rect
) {
  box = { top: box.top, left: box.left, width: box.width, height: box.height }
  for (let p = element; p; p = p.parentElement) {
    if (p.classList.contains('editor-content')) {
      break
    }
    if (window.getComputedStyle(p).overflow != 'visible') {
      box = intersectRect(box, getBoundingClientRect(p) as Rect)
    }
  }
  return box
}

export interface Measurement {
  rect: Rect
  parentRect: Rect
  rects: Rect[]
  element: HTMLElement
  styles: CSSStyleDeclaration
}

export function measureElement(
  element: HTMLElement,
  getBoundingClientRect?: (element: HTMLElement, rect?: Rect) => Rect,
  initialRectangle?: Rect,
  clip = true
): Measurement {
  if (!element || !isInDocument(element)) return null
  const rect = clip
    ? getClippedRectangle(element, getBoundingClientRect, initialRectangle)
    : getBoundingClientRect(element)
  const parentRect = getClippedRectangle(element.parentElement, getBoundingClientRect, initialRectangle)
  return {
    element,
    parentRect,
    rect: rect,
    rects: Array.from(element.getClientRects()).map((r) =>
      getClippedRectangle(element, getBoundingClientRect, getBoundingClientRect(element, r))
    ),
    styles: window.getComputedStyle(element)
  }
}
