export type Rect = {
  top: number
  left: number
  width: number
  height: number
}
export function intersectRect(r1: Rect, r2: Rect): Rect {
  var x = Math.max(r1.left, r2.left)
  var y = Math.max(r1.top, r2.top)
  var xx = Math.min(r1.left + r1.width, r2.left + r2.width)
  var yy = Math.min(r1.top + r1.height, r2.top + r2.height)
  return { top: y, left: x, width: Math.max(0, xx - x), height: Math.max(0, yy - y) }
}

export function unionRect(r1: Rect, r2: Rect) {
  if (!r2) return { ...r1 }
  if (!r1) return { ...r2 }
  var x = Math.min(r1.left, r2.left)
  var y = Math.min(r1.top, r2.top)
  var xx = Math.max(r1.left + r1.width, r2.left + r2.width)
  var yy = Math.max(r1.top + r1.height, r2.top + r2.height)
  return { top: y, left: x, width: xx - x, height: yy - y }
}

function dist(x1: number, y1: number, x2: number, y2: number) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
}

export function distanceRect(r1: Rect, r2: Rect) {
  const left = r2.width + r2.left < r1.left
  const right = r1.width + r1.left < r2.left
  const bottom = r2.height + r2.top < r1.top
  const top = r1.height + r1.top < r2.top
  if (top && left) return dist(r1.left, r1.height + r1.top, r2.width + r2.left, r2.top)
  else if (left && bottom) return dist(r1.left, r1.top, r2.width + r2.left, r2.height + r2.top)
  else if (bottom && right) return dist(r1.width + r1.left, r1.top, r2.left, r2.height + r2.top)
  else if (right && top) return dist(r1.width + r1.left, r1.height + r1.top, r2.left, r2.top)
  else if (left) return r1.left - (r2.width + r2.left)
  else if (right) return r2.left - (r1.width + r1.left)
  else if (bottom) return r1.top - (r2.height + r2.top)
  else if (top) return r2.top - (r1.height + r1.top)
  else return 0
}

export function getRectStyle(rect: Rect, usePosition = false) {
  return rect != null && rect.width && rect.height
    ? usePosition
      ? {
          left: rect.left,
          top: rect.top,
          width: rect.width,
          height: rect.height
        }
      : {
          transform: `translate(${rect.left}px, ${rect.top}px)`,
          width: rect.width,
          height: rect.height
        }
    : { ...rect, transform: 'translate(-1000px, -1000px)' }
}

export function offsetRect(rect: Rect, offset: number) {
  return {
    left: rect.left + offset,
    top: rect.top + offset,
    width: rect.width - offset * 2,
    height: rect.height - offset * 2
  }
}

export interface Point {
  left: number
  top: number
}
export function distanceRectToPoint(rect: Rect, p: Point) {
  var dx = Math.max(rect.left - p.left, 0, p.left - (rect.left + rect.width))
  var dy = Math.max(rect.top - p.top, 0, p.top - (rect.top + rect.height))
  return Math.sqrt(dx * dx + dy * dy)
}

export function isPointInsideRectangle(point: Point, rect: Rect) {
  return (
    rect.left <= point.left &&
    point.left <= rect.left + rect.width &&
    rect.top <= point.top &&
    point.top <= rect.top + rect.height
  )
}

export type Line = [start: Point, end: Point]
export function getDistanceFromPointToLine(point: Point, [from, to]: Line) {
  var len2 = Math.pow(to.left - from.left, 2) + Math.pow(to.top - from.top, 2)

  if (len2 === 0) {
    return Math.sqrt(Math.pow(point.left - from.left, 2) + Math.pow(point.top - from.top, 2))
  }

  var t = Math.max(
    0,
    Math.min(
      1,
      ((point.left - from.left) * (to.left - from.left) + (point.top - from.top) * (to.top - from.top)) / len2
    )
  )

  var closestX = from.left + t * (to.left - from.left)
  var closestY = from.top + t * (to.top - from.top)

  return Math.sqrt(Math.pow(point.left - closestX, 2) + Math.pow(point.top - closestY, 2))
}

export function getDistanceFromPointToRect(point: Point, rect: Rect) {
  // Find the closest point on the rectangle to the point
  let closestX = Math.max(rect.left, Math.min(point.left, rect.left + rect.width))
  let closestY = Math.max(rect.top, Math.min(point.top, rect.top + rect.height))

  // Calculate the distance between the closest point and the point
  let distanceX = point.left - closestX
  let distanceY = point.top - closestY
  let distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)

  return distance
}

export function getDistanceFromPointToPoint(point: Point, other: Point) {
  return Math.sqrt(Math.pow(point.left - other.left, 2) + Math.pow(point.top - other.top, 2))
}

export function getLineSegmentCenter(line: Line) {
  return {
    left: line[0].left + (line[1].left - line[0].left) / 2,
    top: line[0].top + (line[1].top - line[0].top) / 2
  }
}

export function getRectCenter(rect: Rect) {
  return {
    left: rect.left + rect.width / 2,
    top: rect.top + rect.height / 2
  }
}

export function getRoundedRectPath(
  x: number,
  y: number,
  width: number,
  height: number,
  topLeftRadius: number,
  topRightRadius: number,
  bottomLeftRadius: number,
  bottomRightRadius: number
): string {
  const minSize = Math.min(width, height)
  topLeftRadius = Math.min(topLeftRadius, minSize / 2)
  topRightRadius = Math.min(topRightRadius, minSize / 2)
  bottomLeftRadius = Math.min(bottomLeftRadius, minSize / 2)
  bottomRightRadius = Math.min(bottomRightRadius, minSize / 2)

  const path =
    `M ${x + topLeftRadius},${y} ` +
    `L ${x + width - topRightRadius},${y} ` +
    `Q ${x + width},${y} ${x + width},${y + topRightRadius} ` +
    `L ${x + width},${y + height - bottomRightRadius} ` +
    `Q ${x + width},${y + height} ${x + width - bottomRightRadius},${y + height} ` +
    `L ${x + bottomLeftRadius},${y + height} ` +
    `Q ${x},${y + height} ${x},${y + height - bottomLeftRadius} ` +
    `L ${x},${y + topLeftRadius} ` +
    `Q ${x},${y} ${x + topLeftRadius},${y} ` +
    `Z`

  return path
}
